export default function ({ store, redirect, route }) {
	if (store.getters['user/user']) {
		if (route.name === 'login') {
			if (route.path.includes('forgot-password')) {
				redirect({
					name: 'home'
				})
			} else {
				redirect({
					name: 'account'
				})
			}
		} else if (route.name === 'reset-password') {
			redirect({
				name: 'home'
			})
		}
	} else {
		if (route.meta[route.meta.length - 1].auth) {
			redirect({
				name: 'login'
			})
		}
	}
}
