<template>
	<div class="table-cell w-full h-full">
		<Nuxt />
	</div>
</template>

<script>
	export default {
		computed: {
			error() {
				return this.$store.getters.error
			}
		},
		watch: {
			async error(e) {
				if (e) {
					this.showError(e)
				}
			},
		},
		beforeCreate() {
			const cookieLang = this.$i18n.getLocaleCookie()
			const routeLang = this.$route.params.lang

			if (routeLang !== cookieLang) {
				this.$i18n.setLocale(routeLang)
			}
		},
		mounted() {
			if (this.error) {
				this.showError(this.error)
			}
		},
		methods: {
			showError(e) {
				this.$toast.error(typeof e === 'object' ? e.response?.data?.message || e.message : e)
				this.$store.commit('clearError')
			}
		}
	}
</script>
