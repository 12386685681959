const getCookie = (name, cookie) => {
	const value = `; ${cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) {
		return decodeURIComponent(parts.pop().split(';').shift())
	}
}

const getDatepickerTranslations = locale => {
	if (locale === 'ru') {
		return {
			formatLocale: {
				months: ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'],
				monthsShort: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
				weekdays: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
				weekdaysShort: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
				weekdaysMin: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
			},
		}
	}

	return {
		formatLocale: {
			months: ['yan', 'fev', 'mar', 'apr', 'may', 'iyun', 'iyul', 'avg', 'sen', 'okt', 'noy', 'dek'],
			monthsShort: ['Yanvar', 'Fevral', 'Mart', 'Aprel', 'May', 'Iyun', 'Iyul', 'Avgust', 'Sentyabr', 'Oktyabr', 'Noyabr', 'Dekabr'],
			weekdays: ['Yakshanba', 'Dushanba', 'Seshanba', 'Chorshanba', 'Payshanba', 'Juma', 'Shanba'],
			weekdaysShort: ['ya', 'du', 'se', 'ch', 'pa', 'ju', 'sh'],
			weekdaysMin: ['ya', 'du', 'se', 'ch', 'pa', 'ju', 'sh'],
		}
	}
}

const generateOfferFeatures = offer => {
	const features = {}
	const baggage = []
	const handbags = []
	const change = []
	const refund = []

	offer.directions.forEach(direction => {
		baggage.push(direction.segments.map(segment => segment.baggage?.piece === null ? null : !!segment.baggage?.piece))
		handbags.push(direction.segments.map(segment => !!segment.handbags?.piece))
		change.push(direction.segments.map(segment => segment.change))
		refund.push(direction.segments.map(segment => segment.refund))
	})

	if (baggage.flat().every(truthy => truthy)) {
		features.baggage = true
	} else if (baggage.flat().some(piece => piece === null)) {
		features.baggage = null
	} else if (baggage.flat().every(truthy => !truthy) || offer.directions.length === 1) {
		features.baggage = false
	}

	if (handbags.flat().every(truthy => truthy)) {
		features.handbags = true
	} else if (handbags.flat().every(truthy => !truthy) || offer.directions.length === 1) {
		features.handbags = false
	}

	if (change.flat().every(truthy => truthy)) {
		features.change = true
	} else if (change.flat().every(truthy => !truthy) || offer.directions.length === 1) {
		features.change = false
	}

	if (refund.flat().every(truthy => truthy)) {
		features.refund = true
	} else if (refund.flat().every(truthy => !truthy) || offer.directions.length === 1) {
		features.refund = false
	}

	return features
}

export {
	getCookie,
	getDatepickerTranslations,
	generateOfferFeatures,
}
