import Vue from 'vue'
import Router from 'vue-router'
import config from '../../nuxt.config.js'

const locales = config.i18n.locales.join('|')
const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;

Router.prototype.push = function push(location, onComplete, onAbort) {
	const result = originalPush.call(
		this,
		location,
		onComplete,
		onAbort
	);
	if (result) {
		return result.catch(err => {
			if (err.name !== 'NavigationDuplicated') {
				throw err;
			}
		});
	}
	return result;
};

Router.prototype.replace = function replace(location, onComplete, onAbort) {
	const result = originalReplace.call(
		this,
		location,
		onComplete,
		onAbort
	);
	if (result) {
		return result.catch(err => {
			if (err.name !== 'NavigationDuplicated') {
				throw err;
			}
		});
	}
	return result;
};

Vue.use(Router)

const routes = [
	{
		path: `/:lang(${locales})/iframe`,
		name: 'iframe',
		component: () => lazyLoad(import('~/views/Iframe')),
	},
	{
		path: `/:lang(${locales})/esim`,
		name: 'esim',
		component: () => lazyLoad(import('~/views/Esim')),
	},
	{
		path: `/:lang(${locales})/geotrend`,
		name: 'geotrend',
		component: () => lazyLoad(import('~/views/Geotrend')),
	},
	{
		path: `/:lang(${locales})/webview/`,
		component: () => lazyLoad(import('~/views/WebView')),
		children: [
			{
				path: 'public-offer',
				component: () => lazyLoad(import('~/views/PublicOffer')),
			},
			{
				path: 'privacy-policy',
				component: () => lazyLoad(import('~/views/PrivacyPolicy')),
			},
			{
				path: 'terms-of-use',
				component: () => lazyLoad(import('~/views/TermsOfUse')),
			}
		]
	},
	{
		path: `/:lang(${locales})`,
		component: () => lazyLoad(import('~/views/Root')),
		children: [
			{
				path: '',
				name: 'home',
				component: () => lazyLoad(import('~/views/Home')),
			},
			{
				path: 'info/company',
				name: 'about-company',
				component: () => lazyLoad(import('~/views/AboutCompany')),
			},
			{
				path: 'info/public-offer',
				name: 'public-offer',
				component: () => lazyLoad(import('~/views/PublicOffer')),
			},
			{
				path: 'info/privacy-policy',
				name: 'privacy-policy',
				component: () => lazyLoad(import('~/views/PrivacyPolicy')),
			},
			{
				path: 'info/covid-19',
				name: 'covid',
				component: () => lazyLoad(import('~/views/Covid')),
			},
			{
				path: 'info/covid',
				redirect: {
					name: 'covid'
				}
			},
			{
				path: 'info/terms-of-use',
				name: 'terms-of-use',
				component: () => lazyLoad(import('~/views/TermsOfUse')),
			},
			{
				path: 'info/contacts',
				name: 'contacts',
				component: () => lazyLoad(import('~/views/Contacts')),
			},
			{
				path: 'info/payment',
				name: 'payment',
				component: () => lazyLoad(import('~/views/Payment')),
			},
			{
				path: 'insurance/apex/baggage/about',
				name: 'apex-about-baggage',
				component: () => lazyLoad(import('~/views/ApexAboutBaggage')),
			},
			{
				path: 'insurance/apex/flight-delay/about',
				name: 'apex-flight-delay',
				component: () => lazyLoad(import('~/views/ApexFlightDelay')),
			},
			{
				path: 'insurance/apex/passport-lost/about',
				name: 'apex-passport-lost',
				component: () => lazyLoad(import('~/views/ApexPassportLost')),
			},
			{
				path: 'insurance/apex/trip-cancellation/about',
				name: 'apex-trip-cancellation',
				component: () => lazyLoad(import('~/views/ApexTripCancellation')),
			},
			{
				path: 'insurance/gross/about',
				name: 'gross-about-insurance',
				component: () => lazyLoad(import('~/views/GrossAboutInsurance')),
			},
			{
				path: 'insurance',
				name: 'insurance',
				component: () => lazyLoad(import('~/views/Insurance')),
			},
			{
				path: 'avia/offers/:aviaQuery',
				name: 'avia-offers',
				component: () => lazyLoad(import('~/views/AviaSearchResultList')),
				props: true
			},
			{
				path: 'avia/offers/:aviaQuery/:id/booking-page',
				name: 'avia-booking-page',
				component: () => lazyLoad(import('~/views/AviaShowBookingPage')),
				props: true
			},
			{
				path: 'avia/offers/:id/rules',
				name: 'avia-offer-rules',
				component: () => lazyLoad(import('~/views/AviaOfferRules')),
			},
			{
				path: 'avia/questionnaire',
				name: 'avia-questionnaire',
				component: () => lazyLoad(import('~/views/AviaQuestionnaire')),
			},
			{
				path: 'avia/routes/:codes',
				name: 'avia-landing',
				component: () => lazyLoad(import('~/views/AviaLanding')),
			},
			{
				path: 'train/offers/:trainQuery',
				name: 'train-offers',
				component: () => lazyLoad(import('~/views/TrainSearchResultList')),
				props: true
			},
			{
				path: 'train/offers/:trainQuery/:departureTrainId/:arrivalTrainId?/booking-page',
				name: 'train-booking-page',
				component: () => lazyLoad(import('~/views/TrainBookingPage')),
				props: true
			},
			{
				path: 'login',
				name: 'login',
				alias: 'forgot-password',
				component: () => lazyLoad(import('~/views/Login')),
			},
			{
				path: 'reset-password',
				name: 'reset-password',
				component: () => lazyLoad(import('~/views/ResetPassword')),
			},
			{
				path: 'account',
				name: 'account',
				meta: { auth: true },
				component: () => lazyLoad(import('~/views/AccountHomePage')),
			},
			{
				path: 'account/orders/:id',
				name: 'order-page',
				meta: { auth: true },
				component: () => lazyLoad(import('~/views/AccountOrderPage')),
			},
			{
				path: 'passengers',
				name: 'passengers',
				meta: { auth: true },
				component: () => lazyLoad(import('~/views/AccountPassengers')),
			},
			{
				path: 'payment-successful',
				name: 'payment-successful',
				component: () => lazyLoad(import('~/views/PaymentSuccessful')),
			},
			{
				path: 'payment-failed',
				name: 'payment-failed',
				component: () => lazyLoad(import('~/views/PaymentFailed')),
			},
			{
				path: 'tashkent-airport-scoreboard',
				name: 'tashkent-airport-scoreboard',
				component: () => lazyLoad(import('~/views/AirportScoreboard')),
			},
			{
				path: 'samarkand-airport-scoreboard',
				name: 'samarkand-airport-scoreboard',
				component: () => lazyLoad(import('~/views/AirportScoreboard')),
			},
			{
				path: 'andijan-airport-scoreboard',
				name: 'andijan-airport-scoreboard',
				component: () => lazyLoad(import('~/views/AirportScoreboard')),
			},
			{
				path: 'bukhara-airport-scoreboard',
				name: 'bukhara-airport-scoreboard',
				component: () => lazyLoad(import('~/views/AirportScoreboard')),
			},
			{
				path: 'urgench-airport-scoreboard',
				name: 'urgench-airport-scoreboard',
				component: () => lazyLoad(import('~/views/AirportScoreboard')),
			},
			{
				path: 'termez-airport-scoreboard',
				name: 'termez-airport-scoreboard',
				component: () => lazyLoad(import('~/views/AirportScoreboard')),
			},
			{
				path: 'namangan-airport-scoreboard',
				name: 'namangan-airport-scoreboard',
				component: () => lazyLoad(import('~/views/AirportScoreboard')),
			},
			{
				path: 'nukus-airport-scoreboard',
				name: 'nukus-airport-scoreboard',
				component: () => lazyLoad(import('~/views/AirportScoreboard')),
			},
			{
				path: 'navoiy-airport-scoreboard',
				name: 'navoiy-airport-scoreboard',
				component: () => lazyLoad(import('~/views/AirportScoreboard')),
			},
			{
				path: 'fergana-airport-scoreboard',
				name: 'fergana-airport-scoreboard',
				component: () => lazyLoad(import('~/views/AirportScoreboard')),
			},
			{
				path: 'qarshi-airport-scoreboard',
				name: 'qarshi-airport-scoreboard',
				component: () => lazyLoad(import('~/views/AirportScoreboard')),
			},
			{
				path: '*',
				name: 'error',
				component: () => lazyLoad(import('~/views/Error404')),
			},
		]
	},
]

export function createRouter() {
	return new Router({
		mode: 'history',
		routes: routes,
		scrollBehavior(to, from, savedPosition) {
			if (savedPosition) {
				return savedPosition
			} else if (to.name !== from.name) {
				return { x: 0, y: 0 }
			}
		}
	})
}

function lazyLoad(promise) {
	return promise.then(m => m.default || m)
}
