import { CancelToken } from 'axios'
import { transfers } from "@@/nuxt.config";
let request

export const state = () => {
	return {
		token: null,
	}
}

export const actions = {
	async getToken({commit}, {email, password} = {}) {
		const form = {
			email: email || transfers.EMAIL,
			password: password || transfers.PASSWORD,
		}
		try {
			const week = 604800
			const token = (await this.$transfersApi.post('/users/login', form, {
				transformRequest: (data, headers) => {
					if (process.server) {
						headers['referer'] = ''
						headers['origin'] = ''
					}
					delete headers['Authorization']
					return JSON.stringify(data)
				}
			})).data.data?.token
			commit('setToken', token)
			//this.$cookies.set('transfersToken', token, {path: '/', maxAge: week, sameSite: 'lax'})
			this.$transfersApi.defaults.headers = {...this.$transfersApi.defaults.headers, Authorization: `Bearer ${token}`}
			return token
		} catch (e) {
			commit('setError', e, {root: true})
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async getRoutes({commit}, {iata, phrase}) {
		try {
			if (request) {
				request.cancel()
			}

			request = CancelToken.source()

			const response = await this.$transfersApi.get(`/transfers/airports/find-places-nearby?iata=${iata}&locale=${this.$i18n.locale}&query=${phrase}`, {cancelToken: request.token})
			return response.data.data
		} catch (e) {
			throw e
		}
	},
	cancelRequest() {
		if (request) {
			request.cancel()
		}
	},
	async getVehicles({commit, rootGetters}, {tripType, iata, placeId, adults, children, infants, arrivalDateTime, boosters, childSeats, babySeats}) {
		try {
			if (request) {
				request.cancel()
			}

			request = CancelToken.source()

			const query = `/transfers/airports/find-vehicles?trip_type=${tripType}&iata=${iata}&place_id=${placeId}&adults=${adults}&children=${children}&infants=${infants}&boosters=${boosters}&child_seats=${childSeats}&baby_seats=${babySeats}&arrival_datetime=${arrivalDateTime}&locale=${this.$i18n.locale}`
			const response = await this.$transfersApi.get(query, {cancelToken: request.token})
			const vehicles = response.data.data

			vehicles.sort((a, b) => a.price.UZS.total - b.price.UZS.total)

			return vehicles

			// if (!vehicles) {
			// 	return []
			// }
			//
			// vehicles.forEach(vehicle => {
			// 	if (vehicle.pricing.child_seat?.price.UZS === 'free') {
			// 		vehicle.pricing.child_seat.price.UZS = 0
			// 	}
			// 	if (vehicle.pricing.booster?.price.UZS === 'free') {
			// 		vehicle.pricing.booster.price.UZS = 0
			// 	}
			// })
			//
		} catch (e) {
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async cancelTransfer({commit}, id) {
		try {
			const response = await this.$api.delete(`/transfer/orders/${id}/cancellation`)

			return response.data.data
		} catch (e) {
			if (e.response?.status !== 422) {
				//commit('setError', e, {root: true})
				this.$sentry?.captureException(e)
			}
			throw e
		}
	},
}

export const mutations = {
	setToken(state, payload) {
		state.token = payload
	},
}

export const getters = {

}
