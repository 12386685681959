export const state = () => {
	return {
		serverDate: null,
		isNewYear: false,
		error: null,
	}
}

export const actions = {
	async nuxtServerInit(store, {route, app}) {
		const date = new Date()

		if (!route.matched.length) {
			return
		}

		const userToken = app.$cookies.get('userToken')
		store.commit('setServerDate', date.getTime())
		store.commit('setIsNewYear', date.getMonth() === 0 && date.getDate() <= 14
			|| date.getMonth() === 11 && date.getDate() >= 20)

		try {
			if (userToken) {
				await store.dispatch('user/getUserData', userToken)
			}
		} catch (e) {}
	},
}

export const mutations = {
	setServerDate(state, payload) {
		state.serverDate = payload
	},
	setIsNewYear(state, payload) {
		state.isNewYear = payload
	},
	setError(state, payload) {
		state.error = payload
	},
	clearError(state) {
		state.error = null
	},
}

export const getters = {
	serverDate(state) {
		return state.serverDate
	},
	isNewYear(state) {
		return state.isNewYear
	},
	error(state) {
		return state.error
	},
}
