export default function({isHMR, req, route, app, redirect}) {
	if (isHMR || !req) {
		return
	}

	if (!route.name) {
		let lang = route.path.split('/').filter(item => item)[0]

		if (!app.i18n.locales.includes(lang)) {
			lang = app.i18n.getLocaleCookie() || app.i18n.getBrowserLocale() || app.i18n.defaultLocale
			redirect({
				path: `/${lang}` + route.path,
				query: route.query,
				hash: route.hash,
				params: {lang}
			})
		}
	}
}
