const week = 604800

export const state = () => ({
	user: null,
	savedPassengers: [],
})

export const actions = {
	async registerUser({commit}, form) {
		try {
			const response = (await this.$api.post('/users', form)).data.data
			this.$cookies.set('userToken', response.token, {path: '/', maxAge: week, sameSite: 'lax'})
			this.$api.defaults.headers = {...this.$api.defaults.headers, Authorization: `Bearer ${response.token}`}
			commit('setUser', response.user)
			return response
		} catch (e) {
			if (e.response?.status !== 422) {
				commit('setError', e, {root: true})
			}
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async login({commit}, form) {
		try {
			const response = (await this.$api.post('/users/login', form)).data.data
			this.$cookies.set('userToken', response.token, {path: '/', maxAge: week, sameSite: 'lax'})
			this.$api.defaults.headers = {...this.$api.defaults.headers, Authorization: `Bearer ${response.token}`}
			commit('setUser', response.user)
			return response
		} catch (e) {
			if (![401, 404, 422].includes(e.response?.status)) {
				commit('setError', e, {root: true})
			}
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async requestPasswordResetEmail({commit}, form) {
		try {
			const response = await this.$api.post('/users/password-reset-token', form)
			return response.data.data
		} catch (e) {
			if (![404, 422].includes(e.response?.status)) {
				commit('setError', e, {root: true})
			}
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async resetPassword({commit}, form) {
		try {
			const response = await this.$api.post('/users/password-reset', form)
			return response.data.data
		} catch (e) {
			if (![404, 422].includes(e.response?.status)) {
				commit('setError', e, {root: true})
			}
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async logout({commit}) {
		try {
			await this.$api.delete('/users/logout')
		} catch (e) {
			this.$sentry?.captureException(e)
		} finally {
			this.$cookies.remove('userToken')
			delete this.$api.defaults.headers['Authorization']
			if (this.$router.currentRoute.meta.auth) {
				await this.$router.push({name: 'home'})
			}
			commit('setUser', null)
			commit('setSavedPassengers', [])
		}
	},
	async getUserData({commit}, userToken) {
		try {
			const user = (await this.$api.get('/users')).data.data
			if (userToken) {
				this.$api.defaults.headers = {...this.$api.defaults.headers, Authorization: `Bearer ${userToken}`}
			}
			commit('setUser', user)
			return user
		} catch (e) {
			if ([401, 404].includes(e.response?.status)) {
				this.$cookies.remove('userToken')
			}
			this.$sentry?.captureException(e)
		}
	},
	async getSavedPassengers({commit}) {
		try {
			const passengers = (await this.$api.get('/users/passenger-list')).data.data
			passengers.forEach(passenger => {
				passenger.birthdate = passenger.birthdate.replace(/-/g, '/')
			})
			commit('setSavedPassengers', passengers)
			return passengers
		} catch (e) {
			if ([401, 404, 4222].includes(e.response?.status)) {
				this.$sentry?.captureException(e)
			}
		}
	},
	async updateUserData({state, commit}, payload) {
		try {
			const response = (await this.$api.post('/users/update-data', payload)).data.data
			commit('setUser', {
				...state.user,
				name: response.name,
				tel: response.tel,
				tel2: response.tel2
			})
			return response
		} catch (e) {
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async createPassenger({state, commit}, payload) {
		try {
			const passenger = (await this.$api.post('/users/passenger-list', payload)).data.data

			if (passenger.document) {
				passenger.document.expire = passenger.document.expire.replace(/-/g, '/')
			}

			if (passenger.documents.intl) {
				passenger.documents.intl.expire = passenger.documents.intl.expire.replace(/-/g, '/')
			}

			if (passenger.documents.id) {
				passenger.documents.id.expire = passenger.documents.id.expire.replace(/-/g, '/')
			}

			commit('setSavedPassengers', [
				...state.savedPassengers,
				passenger
			])

			return passenger
		} catch (e) {
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async updatePassenger({state, commit}, payload) {
		try {
			const passenger = (await this.$api.patch('/users/passenger-list', payload)).data.data
			const passengerIndex = state.savedPassengers.findIndex(passenger => passenger.id === payload.passenger_id)
			const updatedPassengers = [...state.savedPassengers]

			if (passenger.document) {
				passenger.document.expire = passenger.document.expire.replace(/-/g, '/')
			}

			if (passenger.documents.intl) {
				passenger.documents.intl.expire = passenger.documents.intl.expire.replace(/-/g, '/')
			}

			if (passenger.documents.id) {
				passenger.documents.id.expire = passenger.documents.id.expire.replace(/-/g, '/')
			}

			updatedPassengers.splice(passengerIndex, 1, passenger)
			commit('setSavedPassengers', updatedPassengers)

			return passenger
		} catch (e) {
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async deletePassenger({state, commit}, passengerId) {
		try {
			const response = (await this.$api.delete('/users/passenger-list', {
				data: {
					passenger_ids: [ passengerId ]
				}
			})).data.data

			commit('setSavedPassengers', state.savedPassengers.filter(passenger => passenger.id !== passengerId))

			return response
		} catch (e) {
			this.$sentry?.captureException(e)
			throw e
		}
	},
}

export const mutations = {
	setUser(state, payload) {
		state.user = payload
	},
	setSavedPassengers(state, payload) {
		state.savedPassengers = payload
	}
}

export const getters = {
	user(state) {
		return state.user
	},
	savedPassengers(state) {
		return state.savedPassengers
	},
}
