import countriesUz from '@/scripts/countries-uz'

export const state = () => ({
	countries: null,
	currencyRates: null
})

export const actions = {
	async getCountries({commit}) {
		try {
			/*if (process.client && localStorage) {
				const countries = JSON.parse(localStorage.getItem('commonCountries') || null)
				if (Array.isArray(countries) && countries.length && countries[0].hasOwnProperty('visa_types')) {
					countries.forEach(country => country.name_uz = countriesUz[country.code])
					commit('setCountries', countries)
					return countries
				}
			}*/

			const countries = Object.values((await this.$api.get('/countries')).data.data)
			countries.forEach(country => country.name_uz = countriesUz[country.code])
			commit('setCountries', countries)

			if (process.client && localStorage) {
				localStorage.setItem('commonCountries', JSON.stringify(countries))
			}

			return countries
		} catch (e) {
			commit('setError', e, {root: true})
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async getVisasInfo(_, country) {
		try {
			const visas = (await this.$api.get(`/countries/${country}`)).data.data.visas_for_citizens

			visas && Object.keys(visas).forEach(code => {
				visas[code] = visas[code].visa_types.map(type => type.id)
			})

			return visas
		} catch (e) {
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async getCurrencyRates({commit}) {
		try {
			const data = (await this.$api.get('/exchange-rates')).data.data
			const rates = {}
			Object.keys(data).forEach(currency => {
				rates[currency] = Number(data[currency].rate)
			})
			commit('setCurrencyRates', rates)
			return rates
		} catch (e) {
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async feedback({commit}, form) {
		try {
			const response = await this.$api.post('/feedback', form)
			return response.data.data
		} catch (e) {
			if (e.response?.status !== 422) {
				commit('setError', e, {root: true})
			}
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async getCancellationReasons() {
		try {
			const response = await this.$api.get('/cancellation-reasons')
			return response.data.data
		} catch (e) {
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async getNonpaymentReasons() {
		try {
			const response = await this.$api.get('/nonpayment-reasons')
			return response.data.data
		} catch (e) {
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async nonpaymentFeedback({commit}, form) {
		try {
			const response = await this.$api.post('/nonpayment-reason-description', form)
			return response.data
		} catch (e) {
			this.$sentry?.captureException(e)
			throw e
		}
	},
}

export const mutations = {
	setCountries(state, payload) {
		state.countries = payload
	},
	setCurrencyRates(state, payload) {
		state.currencyRates = payload
	}
}

export const getters = {
	countries(state) {
		return state.countries
	},
	currencyRates(state) {
		return state.currencyRates
	},
}
