import {trains} from '../../nuxt.config.js'
import {CancelToken} from 'axios'
let request

export const state = () => {
	return {
		token: null,
		stations: null,
		regions: null,
	}
}

export const actions = {
	cancelRequest() {
		if (request) {
			request.cancel()
		}
	},
	async getToken({state, commit}, {email, password} = {}) {
		if (state.token) {
			return
		}
		const form = {
			username: email || trains.USERNAME,
			password: password || trains.PASSWORD,
		}
		try {
			const week = 604800
			const token = (await this.$trainsApi.post('/login', form, {
				transformRequest: (data, headers) => {
					if (process.server) {
						headers['referer'] = ''
						headers['origin'] = ''
					}
					delete headers['Authorization']
					return JSON.stringify(data)
				}
			})).data.data.access_token
			commit('setToken', token)
			this.$cookies.set('trainToken', token, {path: '/', maxAge: week, sameSite: 'lax'})
			this.$trainsApi.defaults.headers = {...this.$trainsApi.defaults.headers, Authorization: `Bearer ${token}`}
			return token
		} catch (e) {
			commit('setError', e, {root: true})
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async getStations({state, commit, dispatch}, {name, isCancel = true}) {
		try {
			if (!state.token) {
				await dispatch('getToken')
			}

			if (request && isCancel) {
				request.cancel()
			}

			request = CancelToken.source()

			const response = await this.$trainsApi.get(`trains/stations?name=${name}`, {cancelToken: request.token})
			commit('setStations', response.data)
			return response.data
		} catch (e) {
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async getRegions({state, commit, dispatch}) {
		try {
			if (!state.token) {
				await dispatch('getToken')
			}
			const response = await this.$trainsApi.get('trains/regions')
			const regions = response.data.map(region => {
				return {
					value: region.id,
					label: region.name[this.$i18n.locale]
				}
			})
			commit('setRegions', regions)
			return regions
		} catch (e) {
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async getTrains({state, commit, dispatch, rootGetters}, {stationFrom, stationTo, departureDate, arrivalDate}) {
		const query = [
			`station_from=${stationFrom}`,
			`station_to=${stationTo}`,
			`departure_date=${departureDate}`,
		]
		if (arrivalDate) {
			query.push(`arrival_date=${arrivalDate}`)
		}
		try {
			if (!state.token) {
				await dispatch('getToken')
			}

			const directions = (await this.$trainsApi.get(`trains?${query.join('&')}`)).data
			const rates = rootGetters['common/currencyRates']

			Object.keys(directions).forEach(direction => {
				directions[direction].forEach(train => {
					train.cars.forEach(car => {
						car.tariffs.forEach(tariff => {
							const price = tariff.cost + tariff.commission_fee + tariff.service_fee
							tariff.total = {
								UZS: price
							}
							Object.keys(rates).forEach(currency => {
								tariff.total[currency] = Number((price / rates[currency]).toFixed(2))
							})
						})
					})
				})
			})

			return directions
		} catch (e) {
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async getPlaces({state, commit, dispatch}, {departureTrainId, arrivalTrainId}) {
		const query = [
			`departure_train_id=${departureTrainId}`,
		]
		if (arrivalTrainId) {
			query.push(`arrival_train_id=${arrivalTrainId}`)
		}
		try {
			if (!state.token) {
				await dispatch('getToken')
			}
			const response = await this.$trainsApi.get(`trains/places?${query.join('&')}`)
			const departureCars = response.data.departure_places.flat()
			const arrivalCars = response.data.departure_places.flat()
			const departureTypes = [...new Set(departureCars.map(car => car.type))]
			const arrivalTypes = [...new Set(arrivalCars.map(car => car.type))]
			const departureGroup = []
			const arrivalGroup = []
			const getCheapestPrice = cars => {
				let cheapestPrice = Infinity
				cars.forEach(car => {
					const total = car.cost + car.commission_fee + car.service_fee
					if (total < cheapestPrice) {
						cheapestPrice = total
					}
				})
				return cheapestPrice
			}

			departureTypes.forEach((type, index) => {
				departureGroup.push({
					type: type,
					cars: departureCars.filter(car => car.type === type)},
				)
				departureGroup[index].seatsCount = departureGroup[index].cars.reduce((seats, car) => seats + car.places.length, 0)
				departureGroup[index].cheapestPrice = getCheapestPrice(departureGroup[index].cars)
			})

			departureGroup.sort((a, b) => a.cheapestPrice - b.cheapestPrice)

			arrivalTypes.forEach((type, index) => {
				arrivalGroup.push({
					type: type,
					cars: departureCars.filter(car => car.type === type)},
				)
				arrivalGroup[index].seatsCount = arrivalGroup[index].cars.reduce((seats, car) => seats + car.places.length, 0)
				arrivalGroup[index].cheapestPrice = getCheapestPrice(arrivalGroup[index].cars)
			})

			arrivalGroup.sort((a, b) => a.cheapestPrice - b.cheapestPrice)

			return {
				departure_places: departureGroup,
				arrival_places: arrivalGroup,
			}
		} catch (e) {
			this.$sentry?.captureException(e)
			throw e
		}
	},
}

export const mutations = {
	setToken(state, payload) {
		state.token = payload
	},
	setStations(state, payload) {
		state.stations = payload
	},
	setRegions(state, payload) {
		state.regions = payload
	},
}

export const getters = {
	stations(state) {
		return state.stations
	},
	regions(state) {
		return state.regions
	},
}
