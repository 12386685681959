export const state = () => ({
	offers: null,
	airportAndCitiesNames: null,
})

export const actions = {
	async getAviaOffers({commit}, query) {
		commit('clearOffers')
		try {
			const offers = (await this.$api.get('/avia/offers?' + query)).data.data
			offers.forEach(offer => {
				offer.directions.forEach(direction => {
					direction.airlineLogos = [...new Set(direction.segments.map(segment => segment.airline.code))]
				})
			})
			const filteredOffers = offers.filter(offer => Math.max(...offer.directions.map(direction => direction.segments.length)) <= 3) //offers with transfers count no more than 3
			commit('setOffers', filteredOffers.length ? filteredOffers : offers)
			return filteredOffers.length ? filteredOffers : offers
		} catch (e) {
			if (e.response.status === 404) {
				commit('setOffers', [])
				return []
			}
			commit('clearOffers')
			commit('setError', e, {root: true})
			throw e
		}
	},
	async getAviaOffer({commit}, id) {
		try {
			const offer = (await this.$api.get('/avia/offers/' + id)).data.data
			offer.directions.forEach(direction => {
				direction.airlineLogos = [...new Set(direction.segments.map(segment => segment.airline.code))]
			})
			return offer
		} catch (e) {
			if (e.response.status !== 422) {
				commit('setError', e, {root: true})
			}
			throw e
		}
	},
	async createAviaBooking({commit}, form) {
		try {
			const response = await this.$api.post('/avia/booking', form)
			return response.data.data
		} catch (e) {
			commit('setError', e, {root: true})
			throw e
		}
	},
	async getAirportHints({commit}, phrase) {
		try {
			const response = await this.$api.get('/avia/airport-hints?phrase=' + phrase)
			return response.data.data
		} catch (e) {
			commit('setError', e, {root: true})
			throw e
		}
	},
	async getAirportAndCitiesNames({commit}) {
		try {
			const names = (await this.$api.get('/avia/airports-and-cities')).data.data
			commit('setAirportAndCitiesNames', names)
			return names
		} catch (e) {
			commit('setError', e, {root: true})
			throw e
		}
	},
	clearOffers({commit}) {
		commit('clearOffers')
	}
}

export const mutations = {
	setOffers(state, payload) {
		state.offers = payload
	},
	clearOffers(state) {
		state.offers = null
	},
	setAirportAndCitiesNames(state, payload) {
		state.airportAndCitiesNames = payload
	}
}

export const getters = {
	offers(state) {
		return state.offers
	},
	airportAndCitiesNames(state) {
		return state.airportAndCitiesNames
	}
}
