import Vue from 'vue'

Vue.directive('click-outside', {
	bind: function (el, binding, vnode) {
		el.mouseDownEvent = function(event) {
			el.isClickOutside = !(el === event.target || el.contains(event.target))
		}
		el.clickOutsideEvent = function(event) {
			if (!(el === event.target || el.contains(event.target) || !el.isClickOutside)) {
				(vnode.context[binding.expression] || binding.value)(event, el)
			}
		}
		document.body.addEventListener('mousedown', el.mouseDownEvent)
		document.body.addEventListener('click', el.clickOutsideEvent, true)
	},
	unbind: function (el) {
		document.body.removeEventListener('mousedown', el.mouseDownEvent)
		document.body.removeEventListener('click', el.clickOutsideEvent, true)
	},
})

Vue.directive('uppercase', (el, binding) => {
	el.value = el.value.toUpperCase()
	el.dispatchEvent(new Event(binding.modifiers.lazy ? 'change' : 'input', {bubbles: true}))
})
