<template></template>

<script>
export default {
	props: ['error'],
	created() {
		this.$store.commit('setError', this.error)
		this.$router.replace({name: 'home', params: {lang: this.$i18n.locale}})
	},
}
</script>
