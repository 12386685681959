export default {
	AD: "Andorra",
	AE: "BAA",
	AF: "Afgʼoniston",
	AG: "Antigua va Barbuda",
	AI: "Angilya",
	AL: "Albaniya",
	AM: "Armaniston",
	AN: "Kerak. Antil orollari",
	AO: "Angola",
	AQ: "Antarktida",
	AR: "Argentina",
	AS: "Amerika Samoasi",
	AT: "Avstriya",
	AU: "Avstraliya",
	AW: "Aruba",
	AX: "Eland orollari",
	AZ: "Ozarbayjon",
	BA: "Bosniya va Gertsegovinya",
	BB: "Barbados",
	BD: "Bangladesh",
	BE: "Belgiya",
	BF: "Burkina-Faso",
	BG: "Bolgariya",
	BH: "Bahrayn",
	BI: "Burundi",
	BJ: "Benin",
	BL: "Sent-Bartelemi",
	BM: "Bermud orollari",
	BN: "Bruney Darussalam",
	BO: "Boliviya",
	BQ: "Bonaire, Saba va S.E.",
	BR: "Braziliya",
	BS: "Bagama orollari",
	BT: "Butan",
	BV: "Buvet oroli",
	BW: "Botsvana",
	BY: "Belarusiya",
	BZ: "Beliz",
	CA: "Kanada",
	CC: "Kokos (Kiling) orollari",
	CD: "Kongo, Dem. respublika",
	CF: "Markaziy Afrika Respublikasi",
	CG: "Kongo",
	CH: "Shveytsariya",
	CI: "Kot-dʼIvuar",
	CK: "Kuk orollari",
	CL: "Chili",
	CM: "Kamerun",
	CN: "Xitoy",
	CO: "Kolumbiya",
	CR: "Kosta-Rika",
	CU: "Kuba",
	CV: "Kabo-Verde",
	CW: "Kyurakao",
	CX: "Rojdestvo oroli",
	CY: "Kipr",
	CZ: "Chexiya",
	DE: "Germaniya",
	DJ: "Jibuti",
	DK: "Daniya",
	DM: "Dominika",
	DO: "Dominik Respublikasi",
	DZ: "Jazoir",
	EC: "Ekvador",
	EE: "Estoniya",
	EG: "Misr",
	EH: "Gʼarbiy Sahara",
	ER: "Eritreya",
	ES: "Ispaniya",
	ET: "Efiopiya",
	FI: "Finlyandiya",
	FJ: "Fiji",
	FK: "Folklend orollari",
	FM: "Mikroneziya, Fed. Sht.",
	FO: "Farer orollari",
	FR: "Fransiya",
	GA: "Gabon",
	GB: "Buyuk Britaniya",
	GD: "Grenada",
	GE: "Gruziya",
	GF: "Fransuz Gvianasi",
	GG: "Gernsi",
	GH: "Gana",
	GI: "Gibraltar",
	GL: "Grenlandiya",
	GM: "Gambiya",
	GN: "Gvineya",
	GP: "Gvadelupa",
	GQ: "Ekvatorial Gvineya",
	GR: "Gretsiya",
	GS: "Janubiy Jorjiya va Janubiy Sandvicheva orollari",
	GT: "Gvatemala",
	GU: "Guam",
	GW: "Gvineya-Bisau",
	GY: "Gayana",
	HK: "Gonkong",
	HM: "Xerd oroli va Makdonald oroli",
	HN: "Gonduras",
	HR: "Xorvatiya",
	HT: "Gaiti",
	HU: "Vengriya",
	ID: "Indoneziya",
	IE: "Irlandiya",
	IL: "Isroil",
	IM: "Man oroli",
	IN: "Hindiston",
	IO: "Britaniyaning Hind okeanidagi hududlari",
	IQ: "Iroq",
	IR: "Eron, Islom Respublikasi",
	IS: "Islandiya",
	IT: "Italiya",
	JE: "Jersi",
	JM: "Yamayka",
	JO: "Iordaniya",
	JP: "Yaponiya",
	KE: "Keniya",
	KG: "Qirgʼiziston",
	KH: "Kambodja",
	KI: "Kiribati",
	KM: "Komor orollari",
	KN: "Sent-Kits va Nevis",
	KP: "Koreya, Xalq Dem. respublika",
	KR: "Janubiy Koreya",
	KW: "Quvayt",
	KY: "Kayman orollari",
	KZ: "Qozogʼiston",
	LA: "Laos",
	LB: "Livan",
	LC: "Sent-Lyusiya",
	LI: "Lixtenshteyn",
	LK: "Shri-Lanka",
	LR: "Liberiya",
	LS: "Lesoto",
	LT: "Litva",
	LU: "Lyuksemburg",
	LV: "Latviya",
	LY: "Liviya",
	MA: "Marokash",
	MC: "Monako",
	MD: "Moldova",
	ME: "Chernogoriya",
	MF: "Sankt-Martin",
	MG: "Madagaskar",
	MH: "Marshall orollari",
	MK: "Makedoniya",
	ML: "Mali",
	MM: "Myanma\t",
	MN: "Moʼgʼuliston",
	MO: "Aomin",
	MP: "Sev. Mariana orollari",
	MQ: "Martinika",
	MR: "Mavritaniya",
	MS: "Montserrat",
	MT: "Malta",
	MU: "Mavrikiy",
	MV: "Maldiv orollari",
	MW: "Malavi",
	MX: "Meksika",
	MY: "Malayziya",
	MZ: "Mozambik",
	NA: "Namibiya",
	NC: "Yangi Kaledoniya",
	NE: "Niger",
	NF: "Norfolk oroli",
	NG: "Nigeriya",
	NI: "Nikaragua",
	NL: "Niderlandiya",
	NO: "Norvegiya",
	NP: "Nepal",
	NR: "Nauru",
	NU: "Niue",
	NZ: "Yangi Zelandiya",
	OM: "Ummon",
	PA: "Panama",
	PE: "Peru",
	PF: "Fransuz polineziyasi",
	PG: "Papua-Yangi Gvineya",
	PH: "Filippin",
	PK: "Pokiston",
	PL: "Polsha",
	PM: "Sent-Pyer va Mikelon",
	PN: "Pitkern",
	PR: "Puerto-Riko",
	PS: "Falastin hududi",
	PT: "Portugaliya",
	PW: "Palau",
	PY: "Paragvay",
	QA: "Qatar",
	RE: "Reyunyon",
	RO: "Ruminiya",
	RS: "Serbiya",
	RU: "Rossiya",
	RW: "Ruanda",
	SA: "Saudiya Arabistoni",
	SB: "Solomon orollari",
	SC: "Seyshel orollari",
	SD: "Sudan",
	SE: "Shvetsiya",
	SF: "Qrim",
	SG: "Singapur",
	SH: "Muqaddas Yelena",
	SI: "Sloveniya",
	SJ: "Shpitsbergen va Yan Mayen",
	SK: "Slovakiya",
	SL: "Syerra-Leone",
	SM: "San-Marino",
	SN: "Senegal",
	SO: "Somali",
	SR: "Surinam",
	SS: "Janubiy Sudan",
	ST: "San-Tome va Prinsipi",
	SV: "El-Salvador",
	SX: "Sint-Marten",
	SY: "Suriya",
	SZ: "Svazilend",
	TC: "Turks va Kaykos orollari",
	TD: "Chad",
	TF: "Fransiya janubiy hududlari.",
	TG: "Togo",
	TH: "Tailand",
	TJ: "Tojikiston",
	TK: "Tokelau",
	TL: "Timor-Leste",
	TM: "Turkmaniston",
	TN: "Tunis",
	TO: "Tonga",
	TR: "Turkiya",
	TT: "Trinidad va Tobago",
	TV: "Tuvalu",
	TW: "Tayvan (Xitoy)",
	TZ: "Tanzaniya",
	UA: "Ukraina",
	UG: "Uganda",
	UM: "AQShning Tinch okeanidagi kichik orollari",
	US: "Qoʼshma Shtatlar",
	UY: "Urugvay",
	UZ: "Oʼzbekiston",
	VA: "Vatikan",
	VC: "Sent-Vinsent va Grenada",
	VE: "Venesuela",
	VG: "Virjiniya orollari, Britaniya.",
	VI: "Virjiniya orollari, AQSh",
	VN: "Vetnam",
	VU: "Vanuatu",
	WF: "Uollis va Futuna",
	WS: "Samoa",
	XK: "Kosovo",
	YE: "Yaman",
	YT: "Mayotte",
	ZA: "Janubiy Afrika",
	ZM: "Zambiya",
	ZW: "Zimbabve",
}
